/** @jsx jsx */
import { jsx, Flex, Themed } from 'theme-ui'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import { Fragment } from 'react'
import BlogPostPreview from './BlogPostPreview'

function BlogPostPreviewList(props) {
  const { heading, nodes, browseMoreHref } = props

  // ANIMATION
  const headingVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.2, duration: 1 },
    },
  }

  return (
    <Fragment>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={headingVariants}
        sx={{ textAlign: 'left', px: 0, pb: 3 }}
      >
        {heading ? (
          <Themed.h6 sx={{ mt: 0, pl: 1, textTransform: 'uppercase' }}>
            {heading}
          </Themed.h6>
        ) : (
          <Themed.h6 sx={{ mt: 0, pl: 1, textTransform: 'uppercase' }}>
            &nbsp;
          </Themed.h6>
        )}
      </motion.div>

      <Flex
        sx={{
          flexDirection: 'column',
          pt: 0,
          pb: 0,
          px: 1,
          contain: 'content',
        }}
      >
        <ul
          sx={{
            listStyle: 'none',
            m: '0',
            p: '0',
          }}
        >
          {nodes &&
            nodes.map(node => (
              <li key={node.id}>
                <BlogPostPreview {...node} />
              </li>
            ))}
        </ul>
      </Flex>
      {browseMoreHref && (
        <div>
          <Link to={browseMoreHref}>Browse more</Link>
        </div>
      )}
    </Fragment>
  )
}

BlogPostPreviewList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '',
}

export default BlogPostPreviewList
