/** @jsx jsx */
import { jsx, Themed, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { graphql } from 'gatsby'
import { PageContainer } from '../styles/Layouts'
import Errors from '../components/Errors'
import Seo from '../components/seo/Seo'
import Layout from '../components/Layout'
import BlogTagHero from '../components/BlogTagHero'
import BlogListTag from '../components/BlogListTag'

const BlogTagTemplate = props => {
  const { data, errors, path, pageContext } = props

  const category = pageContext.name

  if (errors) {
    return <Errors errors={errors} />
  }
  // Site data
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site Settings". Open the studio at http://localhost:4444 and add some content to "Site Settings" and restart the development server.',
    )
  }

  // FOOTER
  const footerNavigation = site.footerNavigation
  const footerText = site.footerText

  // SOCIAL LINKS
  const socialLinks = site.socialLinks

  // Nav Menu items
  const blogPage = data.route !== null ? data.route.page : ''

  const menuItems = blogPage.navMenu && (blogPage.navMenu.items || [])

  // Post data
  const post = data && data.posts

  // SEO
  // const pageSeo = blogPage.seo || []

  // Canonical URL
  const postURL = path
  const siteCanonicalURL = site.url
  const URLCanonical = postURL
    ? [siteCanonicalURL, postURL].join('')
    : siteCanonicalURL

  // Page title
  const seoPostTitle = `${category} | `

  // Page description
  const seoDescription = `Posts about ${category}`

  // Keywords
  const seoKeywords = category
  // const siteKeywords = site.seo.keywords
  // const seoKeywords = postKeywords.length > 0 ? postKeywords : siteKeywords

  return (
    <Layout
      navMenuItems={menuItems}
      siteTitle={site.title}
      svg={site.useLogo}
      svgColor={site.logoColor}
      footerNav={footerNavigation}
      footerCopy={footerText}
      social={socialLinks}
    >
      {post && (
        <Seo
          title={seoPostTitle}
          description={seoDescription}
          // image={post.imageMain}
          canonicalURL={URLCanonical}
          keywords={seoKeywords}
        />
      )}

      <PageContainer>
        {errors && <Errors errors={errors} />}
        {post && (
          <Fragment>
            {data.route === null && (
              <Flex sx={{ width: '50%', margin: 'auto' }}>
                <Themed.h6 sx={{ color: 'orange' }}>
                  This is a blog related page. To correctly render the main menu
                  for this page, in the CMS you must change the 'Title' of the
                  Blog landing page to 'Blog'.
                </Themed.h6>
              </Flex>
            )}
            <BlogTagHero categoryName={category} {...props} />
            <BlogListTag {...props} categoryName={category} />
            {/* Note: passing 'props' not 'post'*/}
          </Fragment>
        )}
      </PageContainer>
    </Layout>
  )
}

export default BlogTagTemplate

export const query = graphql`
  query BlogTagTemplateQuery($name: String!) {
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        isPublished: { eq: true }
        categories: { elemMatch: { name: { eq: $name } } }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          _rawExcerpt
          slug {
            current
          }
          categories {
            _id
            name
            description
          }
          authors {
            _key
            author {
              image {
                crop {
                  _key
                  _type
                  top
                  bottom
                  left
                  right
                }
                hotspot {
                  _key
                  _type
                  x
                  y
                  height
                  width
                }
                asset {
                  _id
                }
              }
              name
            }
          }
        }
      }
    }
    route: sanityRoute(page: { title: { eq: "Blog" } }) {
      page {
        ...PageInfo
        seo {
          description
          seoTitle
          keywords
        }
      }
      slug {
        current
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      url
      useLogo
      logoColor {
        hex
      }
      seo {
        seoTitle
        description
        keywords
      }
      footerNavigation {
        page {
          title
        }
        slug {
          current
        }
      }
      socialLinks {
        type
        link
      }
    }
  }
`
