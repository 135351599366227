/** @jsx jsx */
import { jsx, Themed, Box } from 'theme-ui'
import { motion } from 'framer-motion'
import { format } from 'date-fns'
import { Link } from 'gatsby'
import { getBlogUrl } from '../lib/helpers'
import PortableText from './PortableText'
import BlogTagLink from './BlogTagLink'

function BlogPostPreview(props) {
  const { publishedAt, title, slug, categories, _rawExcerpt } = props

  // ANIMATION
  const headingVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.2, duration: 1 },
    },
  }

  const publishedDateVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.3, duration: 1 },
    },
  }
  const excerptVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.4, duration: 1 },
    },
  }

  const categoryTagVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.5, duration: 1 },
    },
  }

  return (
    <Box sx={{ pb: 5 }}>
      <Link
        to={getBlogUrl(slug.current)}
        sx={{
          textDecoration: 'none',
        }}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          variants={headingVariants}
        >
          <Themed.h4
            sx={{
              variant: 'links.post',
              m: 0,
              p: 1,
            }}
          >
            {title}
          </Themed.h4>
        </motion.div>
      </Link>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={publishedDateVariants}
        sx={{ m: 0, pt: '2', pb: '0', px: '1' }}
      >
        <Themed.p
          sx={{
            color: 'text',
            variant: 'text.paragraph',
            fontWeight: '500',
            mt: 0,
            mb: 0,
          }}
        >
          {format(new Date(publishedAt), 'MMMM do, yyyy')}
        </Themed.p>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={excerptVariants}
        sx={{ px: 1 }}
      >
        {_rawExcerpt && <PortableText blocks={_rawExcerpt} />}
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={categoryTagVariants}
        sx={{ pt: 1 }}
      >
        <BlogTagLink categories={categories} />
      </motion.div>
    </Box>
  )
}

export default BlogPostPreview
