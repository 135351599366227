/** @jsx jsx */
import { jsx, Box, Themed, Flex } from 'theme-ui'
import { Link } from 'gatsby'
import Errors from './Errors'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../lib/helpers'

function BlogTagHero(props) {
  const { data, errors, categoryName } = props
  const name = categoryName.toLowerCase()

  if (errors) {
    return <Errors errors={errors} />
  }

  const postNodes = data
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  // const numberOfArticles =

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['100%', '100%', '100%', '100%'],
        alignItems: 'center',
        contain: 'content',
        px: ['3', '4', '5', '5'],
      }}
    >
      <Box
        sx={{
          mr: [0, 4, 4, 4],
          // py: 4,
        }}
      >
        <Themed.h2>Articles about {name}</Themed.h2>
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Link to={'/blog/'} style={{ textDecoration: 'none' }}>
              <Themed.p sx={{ variant: 'links.noUnderline' }}>
                {' '}
                ←&nbsp;Back to all posts
              </Themed.p>
            </Link>
          </Box>
          <Box>
            <Themed.p>
              {postNodes.length > 1
                ? `${postNodes.length} Articles`
                : `${postNodes.length} Article`}
            </Themed.p>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default BlogTagHero
