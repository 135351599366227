/** @jsx jsx */
import { jsx, Box, Themed } from 'theme-ui'
import { Fragment } from 'react'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../lib/helpers'
import BlogPostPreviewList from './BlogPostPreviewList'
import Errors from './Errors'

const BlogListTag = props => {
  const { data, errors } = props

  if (errors) {
    return <Errors errors={errors} />
  }

  const postNodes = data
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  return (
    <Fragment>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: [' 1fr', '1fr', '2fr 1fr', '2fr 1fr'],
          gap: '8px 32px',
          px: ['3', '4', '5', '5'],
        }}
      >
        <Box>
          {postNodes.length > 0 ? (
            <BlogPostPreviewList nodes={postNodes} heading={props.heading} />
          ) : (
            <Themed.h5>
              We're working on an article about {props.categoryName}. Please
              check back soon.
            </Themed.h5>
          )}
        </Box>
      </Box>
    </Fragment>
  )
}

export default BlogListTag
